// 传感器分类接口
import {
	axios
} from '../../utils/http.js'

const ClassApi = {
	ClassList: '/v3/Class/All',//select下拉数据
	DevSenList: '/v3/Data/Device_Sensor_List',//左侧菜单列表
	DataList: '/v3/Data/Device_Sensor_Data',//表格数据
	DeviceTaskList: '/v3/Device/DeviceTask_List',//时间数据
	SenDataReal: '/v3/Data/Device_Sensor_Data_Real',//时间数据


}
//时间数据
export function SenDataReal(data) {
	return axios({
		url: ClassApi.SenDataReal,
		method: 'post',
		data:data
	})
}
//时间数据
export function DeviceTaskList(data) {
	return axios({
		url: ClassApi.DeviceTaskList,
		method: 'post',
		data:data
	})
}
//表格数据
export function DataList(data) {
	return axios({
		url: ClassApi.DataList,
		method: 'post',
		data:data
	})
}

//左侧菜单列表
export function DevSenList(data) {
	return axios({
		url: ClassApi.DevSenList,
		method: 'post',
		data:data
	})
}
//select下拉数据
export function ClassList(data) {
	return axios({
		url: ClassApi.ClassList,
		method: 'post',
		data:data
	})
}
<template>
	<div id="container" style="width:100%;height:100vh;">

	</div>
</template>

<script>
	// 绘制线路需要的坐标
	var lineArr = []
	import { Loading } from 'element-ui';
	export default {
		props: ['MapList'],
		data() {
			return {
				firstArr: [116.478935, 39.997761], // 中心点/初始坐标
				map: null,
				startIcon:null,
				endIcon:null,
				througIcon:null,
			}
		},
		watch: {
			MapList: {
				handler: function() {
					//console.log('============obj改变了',this.map.de)
					this.map.destroy()
					this.ManageData(this.MapList)
				},
				// 开启深度监听：只要obj中的任何一个属性发生改变，都会触发相应的代码
				deep: true
			}
		},
		mounted() {
			setTimeout(() => {
				this.initMap() // 异步加载（否则报错initMap is not defined）
				// this.initroad()
			}, 1000)
		},
		methods: {
			ManageData(e) {
				console.log('88',e)
				if (!e) {return}
				var that = this
				// //console.log('拿到数据',e)
				// 创建包含4个节点的折线及文字标注
				var path = [];
				var mokerData = [];
				for(let obj of e){
					// //console.log('====',obj)
					let site = obj.T_site.split(',')
					if(Number(site[0])>1){
						path.push([site[0],site[1]])
						mokerData.push([site[0],site[1],obj.T_name, obj.T_t, obj.T_rh, obj.T_time,obj.T_ist,obj.T_ish])
					}
				}
				path = path.reverse()
				mokerData = mokerData.reverse()
				// //console.log('打印path',path)
				AMap.convertFrom(path, 'jps', function (status, result) {
					if (result.info === 'ok') {
						// //console.log('转换',result)
						var path2 = result.locations;
						var polyline2 = new AMap.Polyline({
							path: path2,
							borderWeight: 2, // 线条宽度，默认为 1
							strokeColor: '#409EFF', // 线条颜色
							lineJoin: 'round' // 折线拐点连接处样式
						});
						// 添加maker
						// //console.log('最终数据======',mokerData,path,path2)
						for (var i = 1; i < mokerData.length; i++) {
							// if(i==lineArr.length)  break;
							var marker = new AMap.Marker({
								map: that.map,
								position: path2[i],
								icon: i == path2.length - 1 ? that.endIcon : that.througIcon,
								// icon: i == 0 ? that.startIcon : i == path2.length - 1 ? that.endIcon : that.througIcon,
								autoRotation: true, // 自动旋转
								// offset: new AMap.Pixel(-26, -13), // 调整图片偏移
								// angle: -90 // 图片旋转角度
							})
							
							marker.content = mokerData[i][6]==2 && mokerData[i][7]==1?
								`<div style="padding:10px;color:#000;font-size:12px;line-height: 20px;">
									<div style="font-weight: bold;font-size:12px;">${mokerData[i][2]}<div>
									<div style="font-weight: 100;font-size:12px;">湿度：${mokerData[i][4]}%<div>
									<div style="font-weight: 100;font-size:12px;">时间：${mokerData[i][5]}<div>
								</div>`
								:mokerData[i][6]==1 && mokerData[i][7]==2?`<div style="padding:10px;color:#000;font-size:12px;line-height: 20px;">
									<div style="font-weight: bold;font-size:12px;">${mokerData[i][2]}<div>
									<div style="font-weight: 100;font-size:12px;">温度：${mokerData[i][3]}°C<div>
									<div style="font-weight: 100;font-size:12px;">时间：${mokerData[i][5]}<div>
								</div>`
								:mokerData[i][6]==2 && mokerData[i][7]==2?`<div style="padding:10px;color:#000;font-size:12px;line-height: 20px;">
									<div style="font-weight: bold;font-size:12px;">${mokerData[i][2]}<div>
									<div style="font-weight: 100;font-size:12px;">时间：${mokerData[i][5]}<div>
								</div>`
								:`<div style="padding:10px;color:#000;font-size:12px;line-height: 20px;">
									<div style="font-weight: bold;font-size:12px;">${mokerData[i][2]}<div>
									<div style="font-weight: 100;font-size:12px;">温度：${mokerData[i][3]}°C<div>
									<div style="font-weight: 100;font-size:12px;">湿度：${mokerData[i][4]}%<div>
									<div style="font-weight: 100;font-size:12px;">时间：${mokerData[i][5]}<div>
								</div>`
							marker.on('click', that.markerClick);
							marker.emit('click', {
								target: marker
							}); //默认初始化不出现信息窗体,打开初始化就出现信息窗体
						}
						// that.map.add(marker);

						//单独设置起点
						var marker = new AMap.Marker({
								map: that.map,
								position: path2[0],
								// icon: i == 0 ? that.startIcon : i == path2.length - 1 ? that.endIcon : that.througIcon,
								icon:that.startIcon,

								autoRotation: true, // 自动旋转
								// offset: new AMap.Pixel(-26, -13), // 调整图片偏移
								// angle: -90 // 图片旋转角度
							})
							marker.content = mokerData[0][6]==2 && mokerData[0][7]==1?
								`<div style="padding:10px;color:#000;font-size:12px;line-height: 20px;">
									<div style="font-weight: bold;font-size:12px;">${mokerData[0][2]}<div>
									<div style="font-weight: 100;font-size:12px;">湿度：${mokerData[0][4]}%<div>
									<div style="font-weight: 100;font-size:12px;">时间：${mokerData[0][5]}<div>
								</div>`
								: mokerData[0][6]==1 && mokerData[0][7]==2?`<div style="padding:10px;color:#000;font-size:12px;line-height: 20px;">
									<div style="font-weight: bold;font-size:12px;">${mokerData[0][2]}<div>
									<div style="font-weight: 100;font-size:12px;">温度：${mokerData[0][3]}°C<div>
									<div style="font-weight: 100;font-size:12px;">时间：${mokerData[0][5]}<div>
								</div>`
								: mokerData[0][6]==2 && mokerData[0][7]==2?`<div style="padding:10px;color:#000;font-size:12px;line-height: 20px;">
									<div style="font-weight: bold;font-size:12px;">${mokerData[0][2]}<div>
									<div style="font-weight: 100;font-size:12px;">时间：${mokerData[0][5]}<div>
								</div>`
								:`<div style="padding:10px;color:#000;font-size:12px;line-height: 20px;">
									<div style="font-weight: bold;font-size:12px;">${mokerData[0][2]}<div>
									<div style="font-weight: 100;font-size:12px;">温度：${mokerData[0][3]}°C<div>
									<div style="font-weight: 100;font-size:12px;">湿度：${mokerData[0][4]}%<div>
									<div style="font-weight: 100;font-size:12px;">时间：${mokerData[0][5]}<div>
								</div>`
							marker.on('click', that.markerClick);
							marker.emit('click', {target: marker}); //默认初始化不出现信息窗体,打开初始化就出现信息窗体
						
						// that.map.add(marker);
						that.initroad()
						that.map.add(polyline2)

					}
				});
				this.initMap() 
				// that.firstArr = lineArr[Math.ceil(lineArr.length / 2)]
			},
			markerClick(e) {
				var infoWindow = new AMap.InfoWindow({
					offset: new AMap.Pixel(0, -30)
				})
				infoWindow.setContent(e.target.content);
				if(e.lnglat!=undefined){
					infoWindow.open(this.map, e.target.getPosition());
				}
			},
			// 初始化地图
			initMap() {
				var that = this
				//console.log('地图初始化',that.firstArr)
				this.map = new AMap.Map('container', {
					// pitch:45, // 地图俯仰角度，有效范围 0 度- 83 度
					// viewMode:'3D' ,// 地图模式
					// terrain: true, // 开启地形图.
					
					resizeEnable: true, // 窗口大小调整
					center: that.firstArr, // 中心 firstArr: [116.478935, 39.997761],
					zoom: that.firstArr[0]=='116.478935'&&that.firstArr[1]=='39.997761'?1:20
					// rotateEnable: false,
					// pitchEnable: false,
					//  center: this.firstArr,
					// zoom: 17,
					// pitch: 65,
					// rotation: 45,
					// viewMode:'3D',//开启3D视图,默认为关闭
					// zooms:[2,20],
				})
				// 创建一个 起点Icon
				this.startIcon = new AMap.Icon({
					// 图标尺寸
					size: new AMap.Size(25, 34),
					// 图标的取图地址
					image: '//a.amap.com/jsapi_demos/static/demo-center/icons/dir-marker.png',
					// 图标所用图片大小
					imageSize: new AMap.Size(135, 40),
					// 图标取图偏移量
					imageOffset: new AMap.Pixel(-9, -3)
				});
				// 创建一个 途径icon
				this.througIcon = new AMap.Icon({
					size: new AMap.Size(25, 34),
					image: '//a.amap.com/jsapi_demos/static/demo-center/icons/dir-marker.png',
					imageSize: new AMap.Size(135, 40),
					imageOffset: new AMap.Pixel(-51.2, -3)
				});
				// 创建一个 终点icon
				this.endIcon = new AMap.Icon({
					size: new AMap.Size(25, 34),
					image: '//a.amap.com/jsapi_demos/static/demo-center/icons/dir-marker.png',
					imageSize: new AMap.Size(135, 40),
					imageOffset: new AMap.Pixel(-95, -3)
				});
			},
			// 初始化轨迹
			initroad() {
				
				//记住
				// AMap.convertFrom(lineArr, function(status, result) {
				// 	if (result.info === 'ok') {
				// 		var path2 = result.locations;
				// 			//console.log('绘制路径',path2)
				// 		polyline2 = new AMap.Polyline({
				// 			map: this.map,
				// 			path: path2,
				// 			showDir: true,
				// 			strokeColor: '#000', // 线颜色--浅蓝色
				// 			strokeWeight: 6, // 线宽
				// 			lineJoin: 'round' // 折线拐点的绘制样式
				// 			// strokeOpacity: 1,     //线透明度
				// 			// strokeStyle: "solid"  //线样式
				// 		});
				// 		this.map.add(polyline2);
				// 	}
				// });
				
				
				// 绘制还未经过的路线
				// this.polyline = new AMap.Polyline({
				// 	map: this.map,
				// 	path: lineArr,
				// 	showDir: true,
				// 	strokeColor: '#000', // 线颜色--浅蓝色
				// 	strokeWeight: 6, // 线宽
				// 	lineJoin: 'round' // 折线拐点的绘制样式
				// 	// strokeOpacity: 1,     //线透明度
				// 	// strokeStyle: "solid"  //线样式
				// })
				// 绘制路过了的轨迹
				// var passedPolyline = new AMap.Polyline({
				// 	map: this.map,
				// 	strokeColor: '#00BBFF', // 线颜色-深蓝色
				// 	path: [
				// 		[106.65, 26.65],
				// 		[106.65, 26.64]
				// 	],
				// 	// strokeOpacity: 1,     //线透明度
				// 	strokeWeight: 6 // 线宽
				// 	// strokeStyle: "solid"  //线样式
				// })
				this.map.setFitView() // 合适的视口
				Loading.service().close()
			}
		}
	}
</script>

<style lang="scss" scoped>

</style>

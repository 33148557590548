<template>
	<div class="mapShow">
		<div class="mapShow-mai">
			<Map2 :MapList="MapList"></Map2>
			<div class="mapShow-mai-Topnav">
				<div>
					<!-- <div>
						<el-button type="primary" size="mini" @click="PirTime(0)">今天</el-button>
						<el-button type="primary" size="mini" @click="PirTime(1)">近一周</el-button>
						<el-button type="primary" size="mini" @click="PirTime(2)">近两周</el-button>
					</div> -->
				</div>
				<div class="mapShow-mai-Topnav-R">
					<el-date-picker v-model="TimeValue" type="datetimerange" range-separator="至"
						:picker-options="pickerOptions" start-placeholder="开始日期" end-placeholder="结束日期" align="right"
						@change="Timechange" :clearable="false">
					</el-date-picker>
					<transition name="el-zoom-in-top">
						<div v-show="show2" class="mapShow-mai-Topnav1">
							<div class="mapShow-mai-Topnav-mods1">
								<div class="mapShow-mai-Topnav-mods1-L">
									<i class="el-icon-info" style="font-size: 18px;"></i>
									<div style="margin-left: 5px;font-weight:400;letter-spacing: 1.5px;">
										点击相应时间段查询轨迹
									</div>
								</div>
								<div class="mapShow-mai-Topnav-mods1-R" style="display: flex;align-items: center;">
									<div style="margin-right: 10px;" @click="SenDataRealFun">实时轨迹</div>
									<div @click="show2 = false">关闭</div>
								</div>
							</div>
							<div v-if="TimeList==null"
								style="color: #5f6477;text-align: center;font-size: 12px;padding: 20px;">没有更多时间</div>
							<div class="mapShow-mai-Topnav-mods2" v-else>
								<template v-if="TimeList.length!=0">
									<div class="mapShow-mai-Topnav-mod" v-for="(item,index) in TimeList" :key="index"
										@click="XaunTime(item)">
										<div class="mapShow-mai-Topnav-mod1">
											{{item.T_Ut_start | SheTime(item.T_Ut_start)}}
										</div>
										<div class="mapShow-mai-Topnav-mod1">
											{{item.T_Ut_end | SheTime(item.T_Ut_end)}}
										</div>
									</div>
								</template>
								<template v-else>
									<div>时间段为空，请选择其他时间段</div>
								</template>
							</div>
						</div>
					</transition>
				</div>
			</div>
			<div class="mapShow-mai-nav">
				<div class="mapShow-mai-nav-mai">
					<div class="mapShow-mai-nav-tit">
						<div class="mapShow-mai-nav-tit-L">
							<img src="@/assets/img/mp.png" style="width: 25px;height:25px;">
							<input class="mapShow-mai-navInput" v-model="pages.T_name" type="text" placeholder="请输入名称,Sn查询" @input="searchs"/>
						</div>
						<div class="mapShow-mai-nav-tit-R">
							<i class="el-icon-search"></i>
						</div> 
					</div>
					<div style="padding: 0 10px 10px 10px;border-bottom:1px solid #ebeef5;">
						<el-select v-model="pages.T_calssid" slot="prepend" placeholder="请选择" @input="selects">
						  <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
						  </el-option>
						</el-select>
					</div>
					<div class="mapShow-mai-nav-cont" @scroll="scroll">
						<div class="radios" :class="ivIndex===index?'radios-on':'radios'" v-for="(item,index) in radioData" :key="index" @click="clickNav(item,index)">
							<div class="radios-L">
								<i class="el-icon-location-information" style="font-size: 18px;"></i>
								<div class="radios-Ltxt">
									<div class="radios-Ltxt1">{{item.T_name}}</div>
									<div class="radios-Ltxt2">{{item.T_sn}}</div>
								</div>
							</div>
							<div class="radios-R">
								<i class="el-icon-arrow-right"></i>
							</div>
						</div>
					</div>
					<div class="mapShow-mai-nav-pag">
						<img src="@/assets/img/bdMap.png" alt="" srcset="">
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	// import AMapLoader from "@amap/amap-jsapi-loader";
	import Map2 from '../../components/maps.vue'
	import {
		ClassList,
		DevSenList,
		DataList,
		DeviceTaskList,SenDataReal
	} from '../../api/dataDisplay/mapShow.js'
	import {
		formatDateChart
	} from '../../utils/Times.js'
	// 设置安全密钥
	// window._AMapSecurityConfig = {
	// 	securityJsCode: '4ddae7ac063e1cdea8b4ebcdfd0d2dab',//安全密钥
	// }
	export default {
		components: {
			Map2
		},
		data() {
			return {
				times:null,
				ParamsIt:{
					T_sn: '',
					T_id: '',
					page: 1,
					page_z: 99999,
				},
				ivIndex:null,
				pickerOptions: {
					shortcuts: [{
						text: '今天',
						onClick(picker) {
							const end = new Date();
							const start = new Date(new Date().toLocaleDateString()).getTime();
							picker.$emit('pick', [start, end]);
						}
					}, {
						text: '最近一周',
						onClick(picker) {
							const end = new Date();
							const start = new Date(new Date().setHours(0, 0, 0, 0)).getTime() - 86400000 * 7;
							picker.$emit('pick', [start, end]);
						}
					}, {
						text: '最近两周',
						onClick(picker) {
							const end = new Date();
							const start = new Date(new Date().setHours(0, 0, 0, 0)).getTime() - 86400000 * 14;
				   picker.$emit('pick', [start, end]);
						}
					}]
				},
				value: '所有选择',
				options: [],
				Page_size: 1,
				radioData: [],
				TimeValue: [
					new Date(new Date().setHours(0, 0, 0, 0)).getTime(), new Date()
				],
				TimeList: [],
				pages: {//导航列表提交参数
					T_sn: '',
					T_name: '',
					T_calssid: '',
					T_MapShow:1,
					page: 1,
					page_z: 20,
				},
				pages1: { //获取时间段
					T_sn: '',
					Time_start: '',
					Time_end: '',
					page: 1,
					page_z: 99999,
				},
				pages2: { //获取轨迹数据
					T_sn: '',
					T_id: '',
					Time_start: '',
					Time_end: '',
					page: 1,
					page_z: 99999,
				},
				radio: [],
				show2: false,
				navNum: 0,
				map: null,
				page: 1,
				mouseTool: null,
				MapList: [],
			}
		},
		mounted() {
			// this.initMap()
			this.GetClassListApi()
			this.GetDevSenListApi()
			// window.onresize = () => (() => {
			// 	this.initMap()
			// })();
		},
		filters: {
			SheTime(time) {
				return formatDateChart(time)
			}
		},
		methods: {
			SenDataRealFun(){
				////console.log('第一次',this.times)
				if(this.times!=null) {
					this.$message.warning('已经开启实时轨迹，请勿重复开操作')
					return
				}
				this.$message.success('已经开启实时轨迹')
				this.times = setInterval(() => {
					this.GetSenDataRealApi()
				}, 60000);
			},
			GetSenDataRealApi() {//实时轨迹
				const loading = this.$loading({
						lock: true,
						text: '获取轨迹  请稍等',
						spinner: 'el-icon-loading',
						background: 'rgba(0, 0, 0, 0.7)'
					});
					SenDataReal(this.ParamsIt).then(res => {
						console.log('获取轨迹列表', res)
						loading.close();
						if (res.data.Data != null) {
							this.MapList = res.data.Data.DeviceSensor_data
						}
					})
			},
			selects(){
				this.radioData = []
				this.pages.page = 1
				this.GetDevSenListApi()
			},
			scroll(e){//滚动加载导航
				let isBottom = e.target.scrollTop + e.target.clientHeight == e.target.scrollHeight
				if(isBottom && this.pages.page<this.Page_size){
					this.pages.page = this.pages.page+1
					this.GetDevSenListApi()
				}
			},
			clickNav(item,index){//点击导航
				console.log('点击')
				clearInterval(this.times)
				this.times = null
				this.MapList = []
				this.TimeList = []
				this.ParamsIt.T_sn = item.T_sn
				this.ParamsIt.T_id = item.T_id

				this.pages1.T_sn = item.T_sn
				this.pages1.T_id = item.T_id
				this.pages1.Time_start = formatDateChart(this.TimeValue[0])
				this.pages1.Time_end = formatDateChart(this.TimeValue[1])
				this.GetDeviceTaskListApi()
				this.ivIndex = index
			},
			XaunTime(item) {//点击自定义时间列表
				console.log('点击',item)
				clearInterval(this.times)
				this.times = null
				this.MapList = []
				this.pages1.Time_start = formatDateChart(item.T_Ut_start)
				this.pages1.Time_end = formatDateChart(item.T_Ut_end)
				this.GetDataListApi()
			},
			Timechange(e) {//点击时间组件选择
				if (e == null) {
					this.pages1.Time_start = ''
					this.pages1.Time_end = ''
					this.pages2.Time_start = ''
					this.pages2.Time_end = ''
				} else {
					this.pages1.Time_start = formatDateChart(e[0])
					this.pages1.Time_end = formatDateChart(e[1])
					
					this.pages2.Time_start = formatDateChart(e[0])
					this.pages2.Time_end = formatDateChart(e[1])
					this.pages1.page = 1
					this.GetDataListApi()
				}

			},
			searchs() {
				////console.log('搜索',this.pages.T_name)
				this.radioData = []
				this.pages.page = 1
				this.GetDevSenListApi()
			},
			GetDeviceTaskListApi() {//获取时间列表
				DeviceTaskList(this.pages1).then(res => {
					if (res.data.Code === 200) {
						this.show2 = true
						this.TimeList = res.data.Data.Data
					}

				})
			},
			GetDataListApi() {//加载轨迹
				const loading = this.$loading({
					lock: true,
					text: '获取轨迹  请稍等',
					spinner: 'el-icon-loading',
					background: 'rgba(0, 0, 0, 0.7)'
				});
				DataList(this.pages1).then(res => {
					////console.log('获取轨迹列表', res)
					loading.close();
					if (res.data.Data != null) {
						this.MapList = res.data.Data.DeviceSensor_data
						// for(var i = 0 ;i<this.MapList.length;i++){
						// 	var langs = this.MapList[i].T_site.split(',')
						// 	AMap.convertFrom(langs, 'gps', function(status, result) {
						// 		if (result.info === 'ok') {
						// 			////console.log('////////',result)
						// 			var lnglats = result.locations[0]; // Array.<LngLat>
						// 			item = [lnglats.lng, lnglats.lat, this.MapList[i].T_name, this.MapList[i].T_t, this.MapList[i].T_rh, this.MapList[i].T_time]
						// 		}
						// 	})
						// }
						// ////console.log('转换1898989',this.MapList)
					}
				})
			},
			GetDevSenListApi() {//列表
				DevSenList(this.pages).then(res => {
					if (res.data.Code === 200) {
						this.Page_size = res.data.Data.Page_size
						// ////console.log('导航列表',res)
						if(res.data.Data.DeviceSensor_lite!=null){
							// if(this.ivIndex==0){
							// 	this.clickNav(res.data.Data.DeviceSensor_lite[this.ivIndex],this.ivIndex)
							// }
							this.radioData = [...res.data.Data.DeviceSensor_lite,...this.radioData]
						}
					}
				})
			},
			GetClassListApi() {//select列表
				var that = this
				ClassList({}).then(res => {
					if (res.data.Code === 200 && res.data.Msg === 'ok!') {
						var Arr = res.data.Data.Data
						Arr.forEach(function(item) {
							var obj = {
								value: '',
								label: ''
							}
							obj.value = item.Id
							obj.label = item.T_name
							that.options.push(obj)
						})
						that.options.unshift({
							value: '',
							label: '所有分类'
						})
					}
				})
			},
		},
	}
</script>

<style lang="scss">
	@import "../../assets/css/dataDisplay/mapShow.scss";
</style>
